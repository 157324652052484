@keyframes bouncing-loader {
    to {
        opacity: 0.1;
        transform: translate3d(0, -1rem, 0);
    }
}

.container-loading{
    position: fixed;
    text-align: center;
    top: 0;
	left: 0;
	width: 100%;
	height: 100%;
    z-index: 9999999;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    opacity: 0.7;
	overflow: hidden;
	.bouncing-loader {
		display: flex;
		justify-content: center;
	}
	.bouncing-loader > div {
		width: 1rem;
		height: 1rem;
		margin: 3rem 0.2rem;
		background: #2A6EF3;
		border-radius: 50%;
		animation: bouncing-loader 0.6s infinite alternate;
	}
	.bouncing-loader > div:nth-child(2) {
		animation-delay: 0.2s;
	}
	.bouncing-loader > div:nth-child(3) {
		animation-delay: 0.4s;
	}
}
.page{
	background: #4d4d4d;
	opacity: 1 !important
}